* {
  margin: 0px;
  padding: 0px;
}
#root {
  min-height: 100vh;
  background-color: #f6f6f6;
}
body {
  color: #23221f;
  font-size: 16px;
  font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
}
button,
input,
select,
textarea {
  font-size: 14px;
  font-family: inherit;
}
span {
  font-family: inherit;
}

.pin-label {
  white-space: pre-line;
}

.gm-style .gm-style-iw-tc::after {
  height: 0px;
}

.window-cont {
  height: fit-content;
  background: transparent;
  text-align: center;
  overflow: visible;
  z-index: -200;
}

.info-window-wrap {
  width: 0;
  height: 0;
}

.info-zIndex {
  position: relative;
  z-index: -1000 !important;
}

.gm-style-iw:has(div[id^="popupEstimateWindow"]) {
  display: none;
  max-width: 0px;
  max-height: 0px;
}

li.ssQIHO-checkbox-menu-item span {
  background: red;
  top: -40px;
  position: absolute;
}
div.gm-style-mtc ul {
  position: absolute;
  top: -40px;
  display: block;
}

.hide-scroll-bar {
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE */
}

.hide-scroll-bar::-webkit-scrollbar {
  display: none; /* chrome & safari & edge */
}
